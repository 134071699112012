export const getUrlParams = keyword => {
  const hashes = window.location.search.slice(window.location.search.indexOf('?') + 1).split('&');
  const params = {};
  hashes.forEach(hash => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val).replace('+', ' ');
  });
  return Object.keys(params).includes(keyword) ? params[keyword] : '';
};

export const getFbp = () => {
  const result = /_fbp=(fb\.[1|0]\.\d+\.\d+)/.exec(window.document.cookie);
  if (!(result && result[1])) {
    return '';
  }
  return result[1];
};

export const fetchAdequatePixel = () => {
  const pixel = getUrlParams('pixel');
  return pixel;
};

export const fetchClientId = async _ => {
  const clientId = await new Promise(resolve => {
    gtag('get', 'G-LN151GQ6W7', 'client_id', resolve);
  });
  return clientId;
};

export const setUserId = phone => {
  gtag('config', 'G-LN151GQ6W7', {
    user_id: phone,
  });
};

export const fetchUtmMap = async () => {
  const utmSource = getUrlParams('utm_source');
  const utmCampaign = getUrlParams('utm_campaign');
  const utmContent = getUrlParams('utm_content');
  const utmMedium = getUrlParams('utm_medium');
  const set = getUrlParams('set');
  const gclid = getUrlParams('gclid');
  const pixelId = fetchAdequatePixel();
  const fbp = getFbp();
  const fbc = getUrlParams('fbclid');
  const gaClientId = await fetchClientId();

  try {
    return {
      utm_source: utmSource,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_medium: utmMedium,
      set,
      gclid,
      pixel_id: pixelId,
      fbp,
      fbc,
      ga_client_id: gaClientId,
    };
  } catch (e) {
    return {
      utm_source: '',
      utm_campaign: '',
      utm_content: '',
      utm_medium: '',
      set: '',
      gclid: '',
      pixel_id: '',
      ga_client_id: '',
      fbp: '',
      fbc: '',
    };
  }
};

export const UtmKey = 'utmItem';
