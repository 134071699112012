import { useEffect } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import coupon from '../assets/coupon.png';
import Button from '../components/Button';
import Text from '../components/Text';

const FreeCreditPage = ({ onButtonClick }) => {
  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });
    amplitude.getInstance().logEvent('view_coupon', {
      referral: 'painful-crm',
    });
  }, []);

  const buttonClickHandler = () => {
    onButtonClick();
    amplitude.getInstance().logEvent('click_receive_coupon', {
      referral: 'painful-crm',
    });
  };
  return (
    <div style={{ width: '100%' }}>
      <Text style={{ width: '100%', marginTop: '1.6rem' }}>
        그렇다면,
        <br />
        우선 지급해드릴게요.
      </Text>
      <div
        style={{ marginTop: '2.8rem', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Coupon src={coupon} />
      </div>
      <SubText>
        타로 무료 상담권을 지급해드렸어요.
        <br />
        타로언니 웹사이트에서 사용해보세요.
        <br />
        ** 상담권은 24시간 뒤에 소멸돼요.
      </SubText>
      <div
        style={{
          width: '100%',
          position: 'fixed',
          padding: '0 2rem',
          bottom: '1.6rem',
          left: 0,
          right: 0,
        }}>
        <Button
          width="100%"
          backgroundColor="#222529"
          text="상담권 받기"
          color="#fff"
          style={{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '2rem',
            textAlign: 'center',
          }}
          onClickHandler={buttonClickHandler}
        />
      </div>
    </div>
  );
};

const SubText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 180%;
  color: #2e1717;
  margin-top: 1.6rem;
`;

const Coupon = styled.img`
  width: 28rem;
  height: 14rem;
`;

export default FreeCreditPage;
