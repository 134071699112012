import { useEffect, useState } from 'react';
import styled from 'styled-components';
import amplitude from 'amplitude-js';
import { toast } from 'react-hot-toast';
import Button from '../components/Button';
import Text from '../components/Text';
import { counselor, IS_TEST } from '../constants';

const Input = styled.textarea`
  border: none;
  width: 100%;
  min-height: 16.7rem;
  padding: 1rem;
  border: 1px solid #222529;
  border-radius: 0.4rem;
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.1rem;
  /* or 162% */
  background: none;
  letter-spacing: 0.15px;

  /* gray 9 */
  color: #222529;

  &::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.1rem;
    letter-spacing: 0.15px;
    color: #888d96;
  }

  &:focus {
    outline: none;
  }
`;

const InputWorryPage = ({ value, onChange, onReady }) => {
  const [submitStarted, setSubmitStarted] = useState(false);
  const [registered, setRegistsered] = useState(false);

  const utmParams = new URLSearchParams(window.location.search);
  const URL = IS_TEST
    ? 'https://test.tarosister.com/api/v1/user/meet-again'
    : `https://api.tarosister.com/api/v1/user/meet-again`;

  const userRegisterHandler = async () => {
    if (!utmParams.has('key')) {
      toast.error('오류가 발생했습니다.');
      return;
    }

    console.log(value);

    const key = utmParams.get('key');
    if (submitStarted) return;
    if (registered) return;

    setSubmitStarted(prev => true);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          key,
          reservation: value,
        }),
        headers: {
          'Content-type': 'application/json',
        },
      });

      if (response.status === 400 && !response.ok) {
        amplitude.getInstance().logEvent('already_registered', {
          key,
          referral: 'painful-crm',
          counselor,
        });
        setTimeout(() => {
          window.open(`https://tarot-sister.com/${window.location.search}`, '_self');
        }, 2000);
      } else if (response.status !== 400 && !response.ok) {
        toast.error('대상 고객이 아닙니다.');
        throw new Error(`${response.status} error`);
      } else {
        const identify = new amplitude.Identify().setOnce('key', key);
        amplitude.getInstance().identify(identify);
        onReady();
        setRegistsered(prev => true);
      }
    } catch (error) {
      console.log(error);
      // Sentry.captureException(error);
      // Sentry.captureMessage(error.message);
    }
    setSubmitStarted(prev => false);
  };

  useEffect(() => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_select_time', { referral: 'painful-crm', counselor });
  }, []);

  return (
    <>
      <Text style={{ width: '100%', fontSize: '2rem' }}>
        <span style={{ color: '#E1354C' }}>아래에서 오늘 상담 가능하신</span>
        <br />
        <span style={{ color: '#E1354C' }}>시간 하나를 골라주세요.</span>
        <br />
        (예: 1번)
        <br />
        <br />
        <span style={{ color: '#E1354C' }}>딱 3자리 남았어요</span>
        <br />
        (1) 11/14 밤 11시 40분 ~ 12시
        <br /> (2) 11/15 새벽 12시 ~ 12시 20분
        <br /> (3) 11/15 새벽 12시 40분 ~ 1시
      </Text>

      <Input placeholder={'1번'} value={value} onChange={onChange} />
      <div
        style={{
          marginTop: '1.6rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Button
          text="선택 완료"
          width="50%"
          color="#fff"
          backgroundColor={value.length >= 1 ? '#222529' : '#CFD4DA'}
          style={{
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
            lineHeight: '6.4rem',
          }}
          onClickHandler={value.length >= 1 ? userRegisterHandler : () => {}}
        />
      </div>
    </>
  );
};

export default InputWorryPage;
