import styled from 'styled-components';
import './loadAmplitude';
import { useState } from 'react';
import amplitude from 'amplitude-js';
import Header from './components/Header';
import Button from './components/Button';
import devider from './assets/border.png';
import './App.css';
import InputWorryPage from './pages/InputWorryPage';
import Notice from './pages/Notice';
import Ready from './pages/Ready';
import { Footer } from './components/Footer';
import { useAsyncEffect } from './hooks/use-async-effect';
import { fetchUtmMap, UtmKey, getUrlParams } from './utils/datautils';
import FreeCreditPage from './pages/FreeCreditPage';
import { counselor } from './constants';

const Devider = styled.img`
  width: 32rem;
  height: 1.07rem;
`;

function App() {
  useAsyncEffect(async () => {
    amplitude.getInstance().init('4515438c3c5c27716eb568e30f8332ca', undefined, {
      includeGclid: true,
      includeUtm: true,
      includeReferrer: true,
    });

    amplitude.getInstance().logEvent('view_main', { referral: 'painful-crm', counselor });
    const source = getUrlParams('utm_source');
    if (source) {
      const utmMap = await fetchUtmMap();
      localStorage.setItem(UtmKey, JSON.stringify(utmMap));
    }
  }, []);
  const [pageNum, setPageNum] = useState(0);
  const buttonClickHandler = () => {
    setPageNum(prev => prev + 1);
  };

  const [isReady, setIsReady] = useState(false);
  const [inputWorry, setInputWorry] = useState('');
  const [inputPhone, setInputPhone] = useState('');

  const inputPhoneChangeHandler = event => {
    setInputPhone(prev => event.target.value);
  };

  const inputWorryChangeHandler = event => {
    setInputWorry(prev => event.target.value);
  };

  const readyStateHandler = () => {
    setPageNum(prev => prev + 1);
    setIsReady(!isReady);
  };

  return (
    <>
      <Header />
      <div
        style={{
          position: 'relative',
          padding: '0 2rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Devider src={devider} />

        {pageNum === 0 && (
          <img
            src={
              'https://tnndonnqnixjfqlzqhxv.supabase.co/storage/v1/object/public/public/landingpage/painful_crm_mina3.png'
            }
            width="100%"
            style={{ maxWidth: '37.2rem', marginTop: '3rem', marginBottom: '5rem' }}
            alt="메인"
          />
        )}
        {pageNum === 1 && (
          <InputWorryPage value={inputWorry} onChange={inputWorryChangeHandler} onReady={buttonClickHandler} />
        )}

        {pageNum === 0 && (
          <Button
            width="90%"
            backgroundColor="#E1354C"
            text="지금 신청하기"
            color="#fff"
            style={{
              position: 'fixed',
              bottom: '0.5rem',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '2rem',
              textAlign: 'center',
              marginTop: '10rem',
              marginBottom: '2.5rem',
            }}
            onClickHandler={buttonClickHandler}
          />
        )}

        {/* {pageNum === 2 && (
          <Notice
            onButtonClick={buttonClickHandler}
            onClickNo={() => {
              setPageNum(prev => 5);
            }}
            worry={inputWorry}
            onReady={buttonClickHandler}
          />
        )} */}
        {/* {pageNum === 3 && (
          <InputPhonePage
            ready={readyStateHandler}
            value={inputPhone}
            onChange={inputPhoneChangeHandler}
            worry={inputWorry}
          />
        )} */}
        {pageNum === 2 && <Ready />}
        {pageNum === 5 && <FreeCreditPage onButtonClick={buttonClickHandler} />}
      </div>
      {pageNum === 0 && <Footer />}
    </>
  );
}

export default App;
